"use-client";
import React from "react";
import Link from "next/link";
import WhatsAppIcon from "../../assets/Icons/WhatsAppIcon";
import contact from "../../assets/Icons/ContactUsIcon.svg";
import "./index.css";
import { Image } from "antd";

const index = () => {
  return (
    <div className="whatsapp">
      <div className="whatsapp-inner">
        <Link href="#">
          <Image
            height={50}
            width={50}
            preview={false}
            src={contact.src}
            alt="icon"
          />
        </Link>
      </div>
    </div>
  );
};

export default index;
