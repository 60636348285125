// "use client";
// import React, { useEffect } from "react";

// const FreshworksWidget = () => {
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.innerHTML = `
//       window.fwSettings = {
//         'widget_id': 157000000263
//       };
//       if (!window.FreshworksWidget) {
//         window.FreshworksWidget = function() {
//           window.FreshworksWidget.q.push(arguments);
//         };
//         window.FreshworksWidget.q = [];
//       };
//     `;
//     document.head.appendChild(script);

//     const freshworksScript = document.createElement("script");
//     freshworksScript.src =
//       "https://widget.freshworks.com/widgets/157000000263.js";
//     freshworksScript.async = true;
//     freshworksScript.defer = true;
//     document.head.appendChild(freshworksScript);

//     return () => {
//       document.head.removeChild(script);
//       document.head.removeChild(freshworksScript);
//     };
//   }, []);

//   return null;
// };

// export default FreshworksWidget;

"use client";
import React, { useEffect } from "react";
import { usePathname } from "next/navigation";
import Cookies from "js-cookie";

const FreshworksWidget = () => {
  const pathname = usePathname();
  let themeType = Cookies.get("user_type");

  useEffect(() => {
    // const widgetId = themeType === "premier" ? "157000000796" : "157000000263";
    let widgetId;
    if (themeType === "islamic") {
      widgetId = "157000001069";
    } else if (themeType === "premier") {
      widgetId = "157000000796";
    } else {
      // Default widget ID
      widgetId = "157000000263";
    }

    const script = document.createElement("script");
    script.innerHTML = `
      window.fwSettings = {
        'widget_id': ${widgetId}
      };
      if (!window.FreshworksWidget) {
        window.FreshworksWidget = function() {
          window.FreshworksWidget.q.push(arguments);
        };
        window.FreshworksWidget.q = [];
      };
    `;
    document.head.appendChild(script);

    const freshworksScript = document.createElement("script");
    freshworksScript.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;
    freshworksScript.async = true;
    freshworksScript.defer = true;
    document.head.appendChild(freshworksScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(freshworksScript);
    };
  }, [pathname]);

  return null;
};

export default FreshworksWidget;
