//? Library Imports ------------------------------------------------------------>
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Row, Col, List, Radio, Dropdown, Input, Carousel } from "antd";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import Link from "next/link";
//? Assets --------------------------------------------------------------------->
import "./MobileBottomNavigation.scss";
import Flags from "country-flag-icons/react/3x2";
import { AiFillCloseCircle } from "react-icons/ai";
import { CloseCircleOutlined } from "@ant-design/icons";
// import { AiOutlineWhatsApp } from "react-icons/ai";
// import { HiOutlineSquares2X2 } from "react-icons/hi2";
// import { HiOutlineCurrencyDollar } from "react-icons/hi";
import WishListFiledIcon from "../../../../assets/Icons/WishListFiledIcon.svg";
import brandSearchLogo from "../../../../assets/images/mobile/navigation/brand-search-logo.svg";
import HomeIcon from "../../../../assets/Icons/HomeIcon";
import BellIcon from "../../../../assets/Icons/BellIcon.svg";
import CartIcon from "../../../../assets/Icons/CartFilledIcon.svg";
import contact from "../../../../assets/Icons/ContactUsIcon.svg";
import logo_black from "../../../../assets/images/mobile/navigation/small_logo_black.svg";
import contact_selecetd from "../../../../assets/images/mobile/navigation/contact_selected.svg";
import category_selected from "../../../../assets/images/mobile/navigation/category_selected.svg";
import currency_selected from "../../../../assets/images/mobile/navigation/currency_selected.svg";
//top logos here
import MariaBImage from "../../../../assets/images/topBrandsLogos/mariaB.svg";
import Afrozeh from "../../../../assets/images/topBrandsLogos/afrozeh.jpg";
import AsimJofa from "../../../../assets/images/topBrandsLogos/asim_jofa.jpg";
import Gulaal from "../../../../assets/images/topBrandsLogos/gulaal.png";
import HumDum from "../../../../assets/images/topBrandsLogos/Humdum.jpg";
import Mushq from "../../../../assets/images/topBrandsLogos/mushq.jpg";
import Sahane from "../../../../assets/images/topBrandsLogos/sahane-logo.svg";
import SanaSafinaz from "../../../../assets/images/topBrandsLogos/sana_safinaz_logo.png";
//static logo here
import {
  setDefaultCountry,
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../../../redux/Slices/multiLocationSlice";

//? Modules -------------------------------------------------------------------->
import SideNavDrawer from "../Navigation/SideNavDrawer/SideNavDrawer";
import AccountStatus from "@/functions/Cookies/Cookies";

//? Redux Reducers ------------------------------------------------------------->
// import { setDefaultCountry } from "@/redux/Slices/multiLocationSlice";

//? Library Constants ---------------------------------------------------------->

export default function MobileBottomNavigation(props) {
  //? Hooks ---------------------------------------------------------------------->
  const dispatch = useDispatch();
  const router = useRouter();
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  let cartId = Cookies.get("cartId");

  const initialDefaultCurrency = defaultCurrencyFromCookies;
  //? States --------------------------------------------------------------------->
  const [selected, setSelected] = useState(1);
  const [showSideNav, setShowSideNav] = useState(false);
  const [countryData, setCountryData] = useState(null);
  const [showBrandsScreen, setshowBrandsScreen] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState(
    initialDefaultCurrency
  );
  const [countryValue, setCountryValue] = useState(defaultCountryFromCookies);

  const { storefrontListData } = props;
  const defaultCountry = useSelector(
    (state) => state.multiLocation.defaultCountry
  );
  const defaultCountryId = useSelector(
    (state) => state.multiLocation.defaultCountryId
  );
  const [brandListPopulatingArray, setbrandListPopulatingArray] =
    useState(null);
  const [
    allBrandPopulatingArrayForSearch,
    setallBrandPopulatingArrayForSearch,
  ] = useState(null);
  const [allBrandsWithHandle, setAllBrandsWithHandle] = useState(null);
  const { loggedIn, comverse_customer_token } = AccountStatus();

  const cartCountTotal = useSelector((state) => state.cartSlice.cartCountTotal);

  const cartCountTotalValue = cartId ? cartCountTotal : 0;

  //? Functions ------------------------------------------------------------------>
  // Define the API endpoint for geolocation
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };
  const initializeData = async () => {
    if (
      !defaultCountryId
      // ||
      // defaultCurrency === null ||
      // defaultCurrency === undefined
    ) {
      const geoLocationUrl =
        "https://pro.ip-api.com/json/?fields=61439&key=r8KxA4oLfB365n8";
      const countryDataUrl = `${process.env.NEXT_PUBLIC_BACKEND}/storefront/store_list`;

      try {
        const apiData = await fetchData(geoLocationUrl);

        const countryListData = await fetchData(countryDataUrl);

        setCountryData(countryListData);

        const matchingCountry = countryListData?.country_list.find(
          (countryItem) => countryItem.country === apiData.country
        );

        if (matchingCountry) {
          Cookies.set("defaultCurrencyName", matchingCountry.currency, {
            expires: 365,
          });

          Cookies.set("defaultCurrencyId", matchingCountry.id, {
            expires: 365,
          });

          Cookies.set("defaultCountry", matchingCountry.country, {
            expires: 365,
          });

          Cookies.set("geoLocation", matchingCountry.country, {
            expires: 365,
          });

          // setMyDefaultCountry(matchingCountry);
          dispatch(setDefaultCountry(matchingCountry));
          dispatch(changeCountryId(matchingCountry.id));
          dispatch(changeCountry(matchingCountry.country));
          dispatch(changeCountryCode(matchingCountry.short_code));
          dispatch(changeCurrency(matchingCountry.currency));
        } else {
          console.log("No matching country found");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    initializeData();
  }, [countryData, defaultCountry]);

  const dataCurrencyIcon = [];
  props?.data?.country_list?.map((country, index) => {
    dataCurrencyIcon[index] = country;
  });

  const displayCountryFlag = (flagCode) => {
    var Flag = null;
    Flag = Flags[flagCode];
    return Flag && <Flag style={{ width: "28px" }} />;
  };
  useEffect(() => {
    const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
    const defaultCountryFromCookies = Cookies.get("defaultCountry");

    setDefaultCurrency(defaultCurrencyFromCookies);
    setCountryValue(defaultCountryFromCookies);
  }, []);

  useEffect(() => {
    setDefaultCurrency(defaultCurrencyFromCookies);
    setCountryValue(defaultCountryFromCookies);
  }, [defaultCurrencyFromCookies, defaultCountryFromCookies]);
  const handleCurrencyChange = (
    selectedCurrency,
    currencyId,
    short_code,
    currencyName,
    country
  ) => {
    setDefaultCurrency(selectedCurrency);

    const currencyString = String(selectedCurrency);

    Cookies.set("defaultCurrencyName", currencyName, { expires: 365 }); // Store currency name in cookies
    Cookies.set("defaultCurrencyId", currencyId, { expires: 365 });
    Cookies.set("defaultCountry", country, { expires: 365 }); // Store currency ID in cookies
    window.location.reload();

    dispatch(changeCountryId(currencyId));
    dispatch(changeCountry(country));
    dispatch(changeCountryCode(short_code));
    dispatch(changeCurrency(currencyName));
  };
  const items = [
    {
      key: "0",
      label: (
        <div className="flexCenter mobile-currency-header">
          <p>Select Country</p>
          <AiFillCloseCircle onClick={() => handleDropdown("close")} />
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <List
          size="small"
          className="mobile-currency-list"
          dataSource={storefrontListData?.country_list}
          renderItem={(item, index) => (
            <List.Item
              style={{
                padding: "4px 0px",
                fontSize: "12px",
                width: "370px",
              }}
            >
              <Radio.Group
                className="mobile-currency-radioGroup"
                value={defaultCurrency}
                onChange={(e) => {
                  const selectedCurrency = e.target.value;
                  const selectedItem = storefrontListData.country_list.find(
                    (item) => item.currency === selectedCurrency
                  );
                  if (selectedItem) {
                    handleCurrencyChange(
                      selectedCurrency,
                      selectedItem.id,
                      selectedItem.short_code,
                      selectedItem.currency,
                      selectedItem.country
                    );
                  }
                  handleDropdown("close");
                }}
              >
                <Radio value={item.currency} key={item.id}>
                  <Row
                    align="middle"
                    className="radio-item"
                    justify={"space-between"}
                  >
                    <Col span={18} className="currency-name">
                      {item.currency}
                    </Col>
                    <Col span={6}>{displayCountryFlag(item?.short_code)}</Col>
                  </Row>
                </Radio>
              </Radio.Group>
            </List.Item>
          )}
        />
      ),
    },
  ];

  //? Life Cycles ---------------------------------------------------------------->
  const handleDropdownVisibleChange = (visible) => {
    if (!visible) {
      // Call your function when the dropdown is closed
      handleDropdown("close");
    }
  };
  const handleDropdown = (value) => {
    if (value === "open") {
      document
        .querySelector("body")
        .classList.add("overlay-container", "oveflow-hidden");
    } else {
      document
        .querySelector("body")
        .classList.remove("overlay-container", "oveflow-hidden");
    }
  };
  useEffect(() => {
    setSelected(1);
  }, [showSideNav]);

  useEffect(() => {
    if (selected === 3) {
      setshowBrandsScreen(true);
      let topNavBar = document.querySelector(".mobile-header");
      if (topNavBar) {
        topNavBar.style.display = "none";
        document
          .querySelector("body")
          .classList.add("hide-overflow", "brand-drawer");
        let targetElement = document.querySelector(".brand-main-section-div");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "instant" });
        }
      }
      window.scrollTo({ top: 0, behavior: "auto" });
    } else {
      setshowBrandsScreen(false);
      document
        .querySelector("body")
        .classList.remove("hide-overflow", "brand-drawer");
      let topNavBar = document.querySelector(".mobile-header");
      if (topNavBar) {
        topNavBar.style.display = "flex";
      }
    }
    return () => {
      setshowBrandsScreen(false);
      let topNavBar = document.querySelector(".mobile-header");
      document
        .querySelector("body")
        .classList.remove("hide-overflow", "brand-drawer");
      if (topNavBar) {
        topNavBar.style.display = "flex";
      }
    };
  }, [selected]);

  useEffect(() => {
    if (showSideNav) {
      // if (document.querySelector(".mobile-bottom-nav")) {
      //   document
      //     .querySelector(".mobile-bottom-nav")
      //     .classList.remove("display-flex");
      //   document.querySelector("html").classList.add("hide-mobile-bottom-nav");
      // }
    } else {
      if (document.querySelector(".mobile-bottom-nav")) {
        document
          .querySelector(".mobile-bottom-nav")
          .classList.add("display-flex");
        document
          .querySelector("html")
          .classList.remove("hide-mobile-bottom-nav");
      }
    }
  }, [showSideNav]);

  const showTopNavBarAfterLinkIsClicked = () => {
    let topNavBar = document.querySelector(".mobile-header");
    if (topNavBar) {
      topNavBar.style.display = "flex";
    }
  };

  const searchBrands = (searchQuery) => {
    const alphabetObject = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
      9: [],
      A: [],
      B: [],
      C: [],
      D: [],
      E: [],
      F: [],
      G: [],
      H: [],
      I: [],
      J: [],
      K: [],
      L: [],
      M: [],
      N: [],
      O: [],
      P: [],
      Q: [],
      R: [],
      S: [],
      T: [],
      U: [],
      V: [],
      W: [],
      X: [],
      Y: [],
      Z: [],
    };
    // setbrandListPopulatingArray()
    searchQuery = searchQuery.toLowerCase();
    let value;
    let smallcase;
    Object.keys(allBrandPopulatingArrayForSearch).map((item) => {
      value = allBrandPopulatingArrayForSearch[item].includes(searchQuery);
      const data = allBrandPopulatingArrayForSearch[item].map((itemInner) => {
        const firstLetter = itemInner.charAt(0);
        smallcase = itemInner.toLowerCase();
        if (smallcase.includes(searchQuery)) {
          alphabetObject[firstLetter].push(itemInner);
        }
      });
    });
    setbrandListPopulatingArray(alphabetObject);
  };

  function convertToSlug(keywordInput) {
    const firstLetter = keywordInput.charAt(0).toUpperCase();
    let handleArray = { ...allBrandsWithHandle };
    let nameArray = { ...allBrandPopulatingArrayForSearch };
    let indexInArray = nameArray[firstLetter].indexOf(keywordInput);
    let pathLink = handleArray[firstLetter][indexInArray];
    setshowBrandsScreen(false);
    router.push(`/${pathLink}`);
  }

  // useEffect(() => {
  //   if (props?.data) {
  //     populateBrandListObject(
  //       props?.data?.navigation_bar?.main_navigation[1]["children"]
  //     );
  //   }
  // }, [props.data]);

  const handleChatWidget = () => {
    // window.Trengo.Api.Widget.open("chat");
    // window.fcWidget.open();
    window.FreshworksWidget("open");
    document.querySelector("#freshworks-container").style.display = "block";
  };
  return (
    <>
      <Row className="mobile-bottom-nav">
        <div onClick={() => setSelected(1)}>
          <div className="nav-link">
            <Link className="nav-link cart-icon" href={`/`}>
              {selected === 1 ? (
                // <Image height={27} src={HomeIcon} alt="icon" />
                <HomeIcon />
              ) : (
                // <Image height={27} src={HomeIcon} alt="icon" />
                <HomeIcon />
              )}
            </Link>
            {/* <span className={selected === 1 && "link-color"}>Home</span> */}
          </div>
          {/* <div
            className={
              selected !== 1
                ? "nav-bottom-border"
                : "nav-bottom-border nav-bottom-border-color"
            }
          /> */}
        </div>
        {/* <Link
                href={`/cart/checkout?id=${cartId}`}
                style={{ position: "relative" }}
              >
                {cartCountTotalValue > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      textAlign: "center",
                      top: "2px",
                      fontSize: "9px",
                      fontWeight: "bold",
                      color: "#ff0d50",
                      width: "100%",
                    }}
                  >
                    {cartCountTotalValue}
                  </div>
                )}
                <BsHandbag />
              </Link> */}

        <div>
          <Link
            className="nav-link cart-icon"
            href={`/cart/checkout?id=${cartId}`}
          >
            {selected !== 2 ? (
              <>
                <Image
                  height={27}
                  src={CartIcon}
                  alt="icon"
                  style={{ opacity: 0.3 }}
                />
                {cartCountTotalValue > 0 && (
                  <span className="cart-count-value">
                    {cartCountTotalValue}
                  </span>
                )}
              </>
            ) : (
              <Image height={27} src={CartIcon} alt="icon" />
            )}
          </Link>
        </div>
        {/* <div
            className={
              selected !== 2
                ? "nav-bottom-border"
                : "nav-bottom-border nav-bottom-border-color"
            }
          /> */}
        {/* </div> */}
        <SideNavDrawer
          showSideNav={showSideNav}
          userAgent={props?.userAgent}
          setShowSideNav={setShowSideNav}
          data={props?.data?.navigation_bar?.side_navigation}
        />
        <div
          onClick={() => {
            setSelected(5);
            // handleChatWidget();
          }}
        >
          {/* <Link href="https://wa.me/+923158337822?text=Can you please help me with"> */}
          <div className="nav-link" onClick={(e) => handleChatWidget()}>
            <Image src={contact} alt="icon" />
            {/* {selected !== 5 ? (
              <Link href="/pages/static/contact-us">
                <Image src={contact} alt="icon" />
              </Link>
            ) : (
              <Link href="/pages/static/contact-us">
                <Image src={contact} alt="icon" />
              </Link>
            )} */}
            {/* <span className="contact"> </span> */}
            {/* <AiOutlineWhatsApp /> */}
            {/* <span className={selected === 5 && "link-color"}>Contact Us</span> */}
          </div>
          {/* </Link> */}
          {/* <div
            className={
              selected !== 5
                ? "nav-bottom-border"
                : "nav-bottom-border nav-bottom-border-color"
            }
          /> */}
        </div>

        {/* <div onClick={() => setSelected(3)}> */}
        <div>
          <Link
            className="nav-link"
            href={loggedIn && comverse_customer_token ? "/wishlist" : "/login"}
          >
            <Image
              style={{ opacity: "0.5" }}
              src={WishListFiledIcon}
              alt="icon"
              height={27}
            />
          </Link>
          {/* <span className={selected === 3 && "link-color"}>Brands</span> */}
          {/* <div
            className={
              selected !== 3
                ? "nav-bottom-border"
                : "nav-bottom-border nav-bottom-border-color"
            }
          /> */}
        </div>
        <div
          onClick={() => {
            setSelected(4);
          }}
        >
          <div className="nav-link">
            {selected !== 4 ? (
              <Image
                height={27}
                style={{ opacity: "0.5" }}
                src={BellIcon}
                alt="icon"
              />
            ) : (
              <Image
                height={27}
                style={{ opacity: "0.5" }}
                src={BellIcon}
                alt="icon"
              />
            )}
          </div>
        </div>
        {/* <div
          onClick={() => {
            setSelected(4);
          }}
        >
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="top"
            overlayClassName="mobile-currency-dropdown"
            onOpenChange={handleDropdownVisibleChange}
          >
            <div className="nav-link" onClick={() => handleDropdown("open")}>
              {selected !== 4 ? (
                <Image src={BellIcon} alt="icon" />
              ) : (
                <Image src={BellIcon} alt="icon" />
              )}
            </div>
          </Dropdown>
        </div> */}
      </Row>
      {/* TODO: changes here for navigation bottom */}
      {/* <div
        className="brand-main-section-div"
        style={{ display: showBrandsScreen ? "block" : "none" }}
      >
      
        <div className="cross-button-brands-list">
          <CloseCircleOutlined
            onClick={() => {
              setSelected(1);
            }}
          />
        </div>
        <div className="position-relative">
          <Input
            style={{ background: "#EEE", borderRadius: "0px" }}
            placeholder="Search Brands"
            onChange={(e) => {
              searchBrands(e.target.value);
            }}
          />
          <Image
            src={brandSearchLogo}
            height={18}
            width={18}
            alt="search-logo"
            className="position-absolute-search-image"
          />
        </div>
        <div>
          <div className="brands-heading-search-mobile">Top Brands</div>
        </div>
        <div className="horizontal-line-search-brands"></div>
        <Carousel>
          <div className="flex">
            <Link
              className="trending-brands-search-mobile-single-div"
              href={"/maria-b"}
            >
              <div>
                <img
                  src={MariaBImage.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
            <Link
              className="trending-brands-search-mobile-single-div"
              href={"/asim-jofa"}
            >
              <div>
                <img
                  src={AsimJofa.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
            <Link
              className="trending-brands-search-mobile-single-div"
              href={"/mushq"}
            >
              <div>
                <img
                  src={Mushq.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
          </div>
          <div className="flex">
            <Link
              className="trending-brands-search-mobile-single-div sanasafina"
              href={"/sana-safinaz"}
            >
              <div>
                <img
                  src={SanaSafinaz.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
            <Link
              className="trending-brands-search-mobile-single-div"
              href={"/gulaal"}
            >
              <div>
                <img
                  src={Gulaal.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
            <Link
              className="trending-brands-search-mobile-single-div"
              href={"/afrozeh"}
            >
              <div>
                <img
                  src={Afrozeh.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
          </div>
          <div className="flex">
            <Link
              className="trending-brands-search-mobile-single-div"
              href={"/sahane"}
            >
              <div>
                <img
                  src={Sahane.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
            <Link
              className="trending-brands-search-mobile-single-div"
              href={"/humdum"}
            >
              <div>
                <img
                  src={HumDum.src}
                  alt="brand logo"
                  className="top-brand-logo-single-image-bottom-navigation"
                />
              </div>
            </Link>
          </div>
        </Carousel>
     
        <div className="all-brand-list-main-div">
        
          {brandListPopulatingArray &&
            Object.keys(brandListPopulatingArray).map((item, outerIndex) => {
              return (
                <div key={outerIndex}>
                  {brandListPopulatingArray[item].length > 0 && (
                    <h1 className="all-brand-list-main-heading">
                      <div className="all-brand-list-main-heading-text">
                        {item}
                      </div>
                    </h1>
                  )}
                  {brandListPopulatingArray[item].map((innerItem, index) => {
                    return (
                      <div
                        className="all-brand-list-brands-name"
                        onClick={() => {
                          convertToSlug(innerItem);
                        }}
                        key={index}
                      >
                        {innerItem}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div> */}
    </>
  );
}
