{/* <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26.7742 15.1588C26.7452 14.5395 26.7065 13.9346 26.6678 13.3491C26.5643 11.9855 25.9572 10.7091 24.9645 9.76844L22.5887 7.50874L15.7225 0.966727C15.1514 0.423828 14.3935 0.121094 13.6055 0.121094C12.8175 0.121094 12.0597 0.423828 11.4886 0.966727L4.62719 7.50874L2.25136 9.76844C1.25458 10.7066 0.645211 11.9841 0.543272 13.3491C0.480368 14.2153 0.427142 15.1201 0.393271 16.054C0.354561 17.1088 0.335205 18.2072 0.335205 19.3298C0.335205 21.5702 0.417465 23.6847 0.562628 25.6202C0.612006 26.3402 0.907117 27.0214 1.3986 27.5499C1.89008 28.0784 2.54813 28.4221 3.26266 28.5235C3.81428 28.5961 4.39977 28.6686 5.01429 28.7315C6.01591 28.8332 7.09979 28.9154 8.24658 28.9783C8.45712 28.9909 8.66799 28.9602 8.86626 28.8883C9.06454 28.8165 9.24604 28.7048 9.39964 28.5603C9.55323 28.4158 9.67568 28.2414 9.75947 28.0478C9.84327 27.8543 9.88664 27.6457 9.88692 27.4348V19.8331C9.88692 19.0631 10.1928 18.3246 10.7373 17.7802C11.2817 17.2357 12.0202 16.9298 12.7902 16.9298H14.4257C15.1957 16.9298 15.9341 17.2357 16.4786 17.7802C17.0231 18.3246 17.3289 19.0631 17.3289 19.8331V27.4299C17.3292 27.6408 17.3726 27.8494 17.4564 28.043C17.5402 28.2365 17.6626 28.4109 17.8162 28.5555C17.9698 28.7 18.1513 28.8116 18.3496 28.8835C18.5479 28.9554 18.7587 28.986 18.9693 28.9735C20.1161 28.9106 21.2 28.8283 22.2016 28.7267C22.8161 28.6638 23.4016 28.5912 23.9532 28.5186C24.6677 28.4172 25.3258 28.0736 25.8173 27.5451C26.3087 27.0166 26.6039 26.3354 26.6532 25.6154C26.7984 23.6799 26.8807 21.5702 26.8807 19.325C26.871 17.8879 26.8371 16.4846 26.7742 15.1588Z" fill="#D62314" />
</svg> */}
import React from 'react';

const MyIcon = () => (
  <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.7742 15.1588C26.7452 14.5395 26.7065 13.9346 26.6678 13.3491C26.5643 11.9855 25.9572 10.7091 24.9645 9.76844L22.5887 7.50874L15.7225 0.966727C15.1514 0.423828 14.3935 0.121094 13.6055 0.121094C12.8175 0.121094 12.0597 0.423828 11.4886 0.966727L4.62719 7.50874L2.25136 9.76844C1.25458 10.7066 0.645211 11.9841 0.543272 13.3491C0.480368 14.2153 0.427142 15.1201 0.393271 16.054C0.354561 17.1088 0.335205 18.2072 0.335205 19.3298C0.335205 21.5702 0.417465 23.6847 0.562628 25.6202C0.612006 26.3402 0.907117 27.0214 1.3986 27.5499C1.89008 28.0784 2.54813 28.4221 3.26266 28.5235C3.81428 28.5961 4.39977 28.6686 5.01429 28.7315C6.01591 28.8332 7.09979 28.9154 8.24658 28.9783C8.45712 28.9909 8.66799 28.9602 8.86626 28.8883C9.06454 28.8165 9.24604 28.7048 9.39964 28.5603C9.55323 28.4158 9.67568 28.2414 9.75947 28.0478C9.84327 27.8543 9.88664 27.6457 9.88692 27.4348V19.8331C9.88692 19.0631 10.1928 18.3246 10.7373 17.7802C11.2817 17.2357 12.0202 16.9298 12.7902 16.9298H14.4257C15.1957 16.9298 15.9341 17.2357 16.4786 17.7802C17.0231 18.3246 17.3289 19.0631 17.3289 19.8331V27.4299C17.3292 27.6408 17.3726 27.8494 17.4564 28.043C17.5402 28.2365 17.6626 28.4109 17.8162 28.5555C17.9698 28.7 18.1513 28.8116 18.3496 28.8835C18.5479 28.9554 18.7587 28.986 18.9693 28.9735C20.1161 28.9106 21.2 28.8283 22.2016 28.7267C22.8161 28.6638 23.4016 28.5912 23.9532 28.5186C24.6677 28.4172 25.3258 28.0736 25.8173 27.5451C26.3087 27.0166 26.6039 26.3354 26.6532 25.6154C26.7984 23.6799 26.8807 21.5702 26.8807 19.325C26.871 17.8879 26.8371 16.4846 26.7742 15.1588Z" fill="#D62314" />
  </svg>
);

export default MyIcon;