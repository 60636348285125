"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Col,
  Row,
  Modal,
  Space,
  Skeleton,
  Input,
  Typography,
  // Image,
} from "antd";
import axios from "axios";
import Link from "next/link";
import Image from "next/image";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";
import Cookies from "js-cookie";

//? Assets --------------------------------------------------------------------->
import "./MobileSearch.scss";
import { BsSearch } from "react-icons/bs";
import { LeftOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";

//? Modules -------------------------------------------------------------------->
import DynamicButton from "../../Buttons/DynamicButton/DynamicButton";
import ProductCard from "../../ProductsCarousel/ProductCard/ProductCard";
import { usePathname } from "next/navigation";

//? Library Constants ---------------------------------------------------------->
const { Search } = Input;
const { Text } = Typography;

export default function MobileSearch({ userAgent }) {
  const { isMobile, isTablet, isDesktop } = userAgent;
  const path = usePathname();
  //? States --------------------------------------------------------------------->
  const [OpenSearch, setOpenSearch] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [ActivePage, setActivePage] = useState(1);
  const [SearchResults, setSearchResults] = useState();
  const [SearchedProducts, setSearchedProducts] = useState([]);
  const [ShowMegaSearch, setShowMegaSearch] = useState(false);
  const [SearchQuery, setSearchQuery] = useState("");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  //? Functions ------------------------------------------------------------------>
  const handleSearch = (value) => {
    // setLoading(true);
    setSearchQuery(value);
    searchResult(value);
  };

  const searchResult = (query) => {
    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/search_products?page=${ActivePage}&q=` +
          query +
          "&limit=600" +
          `&storefront=${defaultCurrencyIdFromCookies}`
      )
      .then((response) => {
        // console.log("Search Results", response);
        setSearchResults(response?.data);
        setSearchedProducts(response?.data?.results);
        setShowMegaSearch(true);
      })
      .catch(function (error) {
        console.log("Search Api Error", error);
      });
  };

  // console.log("Results State", results);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchQuery = useCallback(debounce(handleSearch, 1000), []);
  return (
    <Col
      className={path.includes("/account") ? "accountsSearch" : "MobileSearch"}
    >
      {!OpenSearch ? <BsSearch onClick={() => setOpenSearch(true)} /> : null}

      {/* {OpenSearch ? (
        <Col className="SearchModal">
          <Row className="width100" gutter={[15]}>
            <Col span={24} className="padding15 backgroundLight">
              <Row className="searchBar alignCenter">
                <Col flex="0 0 auto" className="marginRight10">
                  <LeftOutlined
                    className="cursorPointer"
                    onClick={() => setOpenSearch(false)}
                  />
                </Col>
                <Col flex="1 1 auto">
                  <Search
                    allowClear
                    // onClick={onClick}
                    onSearch={searchQuery}
                    className="borderNone"
                    placeholder="Search ..."
                  />
                </Col>
              </Row>
            </Col>

            <Col
              span={24}
              className="positionRelative padding0"
              style={{ overflowY: "scroll", height: "100vh" }}
            >
              {SearchedProducts?.length ? (
                <Row gutter={[0, 12]} className="positionRelative row">
                  <Col
                    span={24}
                    className="padding15 paddingBottom0"
                    // style={{ zIndex: 10, top: 10, width: "77%" }}
                    // className="positionFixed backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                  >
                    <Text className="font14Bold">Products</Text>
                  </Col>
                  <Col
                    span={24}
                    className="paddingLeft15 paddingRight15"
                    // style={{ top: 60, overflow: "auto" }}
                  >
                    <Row gutter={[10, 15]} className="positionRelative">
                      {SearchedProducts?.map((product, index) => {
                        return (
                          <Col span={12} key={index} className="padding0">
                            <Link
                              href={product?.handle}
                              onClick={() => setShowMegaSearch(false)}
                            >
                              <ProductCard
                                product={product}
                                userAgent={userAgent}
                              />
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Col>
      ) : null} */}

      <Modal
        centered
        footer={null}
        open={OpenSearch}
        wrapClassName="MobileSearchModal"
        onCancel={() => setOpenSearch(false)}
        title={
          <Col span={24} className="padding15 paddingBottom0 backgroundLight">
            <Row className="searchBar alignCenter">
              <Col flex="0 0 auto" className="marginRight10">
                <LeftOutlined
                  className="cursorPointer"
                  onClick={() => setOpenSearch(false)}
                />
              </Col>
              <Col flex="1 1 auto">
                <Search
                  allowClear
                  // onClick={onClick}
                  onSearch={searchQuery}
                  className="borderNone"
                  placeholder="Search ..."
                />
              </Col>
            </Row>
          </Col>
        }
      >
        <Row>
          {/* <Col
            flex="100%"
            className="borderLeft searchProducts positionRelative"
          >
            {SearchedProducts?.length ? (
              <Row gutter={[0, 12]} className="positionRelative row">
                <Col
                  span={24}
                  style={{ zIndex: 10, top: 10, width: "77%" }}
                  className="positionFixed backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                >
                  <Text className="font14Bold">Products</Text>
                </Col>
                <Col
                  span={24}
                  style={{ top: 60, overflow: "auto" }}
                  className="paddingLeft15 paddingRight15"
                >
                  <Row gutter={[10, 15]} className="positionRelative">
                    {SearchedProducts?.map((product, index) => {
                      return (
                        <Col span={4} key={index} className="padding0">
                          <Link
                            href={product?.handle}
                            onClick={() => setOpenSearch(false)}
                          >
                            <ProductCard
                              product={product}
                              userAgent={userAgent}
                            />
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row gutter={[0, 12]} className="positionRelative row">
                <Col
                  span={24}
                  style={{
                    // zIndex: 10,
                    // top: 10,
                    width: "77%",
                  }}
                  // className="positionFixed backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                >
                  <Space
                    align="center"
                    style={{
                      background: "#F8D7DA",
                      padding: "5px",
                      width: "97%",
                      maxWidth: "97%",
                    }}
                  >
                    <ExclamationCircleOutlined
                      style={{
                        fontSize: "21px",
                        color: "#DC3545",
                      }}
                    />
                    <Text className="font14">Sorry, No Result Found</Text>
                  </Space>
                </Col>

                {SearchResults?.featured_collections?.length ? (
                  <>
                    <Col
                      span={24}
                      style={{ width: "77%", marginTop: "70px" }}
                      className="backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                    >
                      <Text className="font14Bold">Featured Collection</Text>
                    </Col>

                    <Col span={24} className="paddingLeft15 paddingRight15">
                      <Row gutter={[10, 15]} className="positionRelative">
                        {SearchResults?.featured_collections?.map(
                          (collection, index) => {
                            return (
                              <Col
                                key={index}
                                flex="14.2%"
                                className="padding0"
                              >
                                <Link
                                  href={collection?.link}
                                  onClick={() => setOpenSearch(false)}
                                >
                                  <Space direction="vertical" align="center">
                                    <img
                                      width={112}
                                      height={111}
                                      src={collection.img}
                                      alt={
                                        collection?.alt_text
                                          ? collection?.alt_text
                                          : "Collection"
                                      }
                                    />
                                    <Text className="font18">
                                      {collection?.title
                                        ? collection?.title
                                        : "N/A"}
                                    </Text>
                                  </Space>
                                </Link>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </Col>
                  </>
                ) : null}

                {SearchResults?.catalogue_lists?.length ? (
                  <>
                    <Col
                      span={24}
                      style={{ width: "77%", marginTop: "0px" }}
                      className="backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                    >
                      <Text className="font14Bold">New Catalog</Text>
                    </Col>

                    <Col span={24} className="paddingLeft15 paddingRight15">
                      <Row gutter={[10, 15]} className="positionRelative">
                        {SearchResults?.catalogue_lists?.map(
                          (catalogue, index) => {
                            return (
                              <Col span={4} className="padding0" key={index}>
                                <Link
                                  href={catalogue?.collection_handle}
                                  onClick={() => setOpenSearch(false)}
                                >
                                  <img
                                    width={163}
                                    height={180}
                                    alt="Catalogue"
                                    src={catalogue?.cdn_link}
                                  />
                                </Link>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </Col>
                  </>
                ) : null}
              </Row>
            )}
          </Col> */}
          <Col
            span={24}
            className="positionRelative padding0"
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            {SearchedProducts?.length ? (
              <Row gutter={[0, 12]} className="positionRelative row">
                <Col
                  span={24}
                  className=""
                  // style={{ zIndex: 10, top: 10, width: "77%" }}
                  // className="positionFixed backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                >
                  <Text className="font14Bold">Products</Text>
                </Col>
                <Col
                  span={24}
                  // className="paddingLeft15 paddingRight15"
                  // style={{ top: 60, overflow: "auto" }}
                >
                  <Row gutter={[10, 15]} className="positionRelative">
                    {SearchedProducts?.map((product, index) => {
                      return (
                        <Col
                          span={12}
                          key={index}
                          className="padding0"
                          onClick={() => setShowMegaSearch(false)}
                        >
                          {/* <Link
                            href={product?.handle}
                            onClick={() => setShowMegaSearch(false)}
                          > */}
                          <ProductCard
                            product={product}
                            userAgent={userAgent}
                          />
                          {/* </Link> */}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            ) : null}
          </Col>

          {/* //* Sticky Buttons Sections -----------------------------------------------> */}

          {SearchedProducts?.length ? (
            <Col
              className="backgroundLight padding5"
              style={{
                left: "0px",
                right: "0px",
                zIndex: "5",
                bottom: "0px",
                position: "fixed",
              }}
            >
              <Row
                className={
                  isMobile || isTablet
                    ? "width100 flexBetween paddingLeft10 paddingRight10 marginBottom25"
                    : isDesktop
                    ? "width100 flexBetween marginTop10 marginBottom10"
                    : ""
                }
              >
                <Col flex="49%">
                  <DynamicButton
                    type="primary"
                    name="Products"
                    className="primary_btn poppinsMedium"
                    // function={addProductToCart}
                    // icon={<HiOutlineShoppingBag color="white" />}
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      borderRadius: "3px",
                      textTransform: "capitalize",
                    }}
                  />
                </Col>
                <Col flex="49%">
                  <DynamicButton
                    name="Related Categories"
                    type="primary"
                    className="primary_btn_outline"
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      borderRadius: "3px",
                      textTransform: "capitalize",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      </Modal>
    </Col>
  );
}
