"use client"
import React, { useEffect } from 'react';

const PingdomScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//rum-static.pingdom.net/pa-5c97b2cacea07b0016000ffe.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  return null; // Since this component is just for script injection, it doesn't render any content
};

export default PingdomScript;
