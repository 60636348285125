// Library Imports
import Link from "next/link";
// import Image from "next/image";
import Cookies from "js-cookie";
import Flags from "country-flag-icons/react/3x2";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  List,
  Radio,
  Dropdown,
  Image,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import whiteLogo from "../../../../assets/images/alfamall white.png";

// Assets, Media & StyleSheets ðŸš€
// import MobileNav from "../../Mobile/MobileNav/MobileNav";
// import SideCart from "../../../features/SideCart/SideCart";
import { changeActiveMenuReducer } from "@/redux/Slices/accountSlice";

import "./MobileHeader.scss";
import { BiUser, BiChevronDown } from "react-icons/bi";
import { BsSearch, BsHandbag } from "react-icons/bs";
import logo from "../../../../assets/images/logos/logo.svg";
import Hamburger from "../../../../assets/Icons/Hamburger.jsx";
// import { ReactComponent as Hamburger } from "../../../../assets/Icons/Hamburger.jsx"; // Update the path as necessary
import LoginUserIcon from "../../../../assets/Icons/LoginUserIcon.svg";

import {
  AiOutlineMenu,
  AiOutlineHeart,
  AiFillCloseCircle,
} from "react-icons/ai";
import { HiOutlineCurrencyDollar } from "react-icons/hi";

import SideNavDrawer from "../Navigation/SideNavDrawer/SideNavDrawer";
import MobileSearch from "../../Search/MobileSearch/MobileSearch";
import SideNav from "@/components/MobileApp/AccountsPageMobile/Modules/SideNav/SideNav";
import SearchForMobile from "../../../shared/Search/MobileSearch/MobileSearchh";
import usePath from "@/hooks/usePath/usePath";
import {
  setDefaultCountry,
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../../../redux/Slices/multiLocationSlice";

import currency from "@/assets/images/mobile/navigation/currency.svg";
import currency_selected from "@/assets/images/mobile/navigation/currency_selected.svg";
import AccountStatus from "@/functions/Cookies/Cookies";
import LoginDropdown from "../Navigation/LoginDropdown/LoginDropdown";

// Library Constants
const { Text } = Typography;

function MobileHeader({ data, userAgent, storefrontListData }) {
  const path = usePath();
  let themeType = Cookies.get("user_type");
  const [displayMobileSearchMenu, setDisplayMobileSearchMenu] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const { isMobile, isTablet, isDesktop } = userAgent;
  const dispatch = useDispatch();
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  let cartId = Cookies.get("cartId");
  const initialDefaultCurrency = defaultCurrencyFromCookies;
  //? Redux States --------------------------------------------------------------->

  //? currency dropdown states & functions --------------------------------------->
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [showCurrencyDrop, setShowCurrencyDrop] = useState(false);
  const { loggedIn, comverse_customer_token } = AccountStatus();
  const displayCountryFlag = (flagCode) => {
    var Flag = null;
    Flag = Flags[flagCode];
    return Flag && <Flag style={{ width: "28px" }} />;
  };

  const handleCurrencyChange = (
    selectedCurrency,
    currencyId,
    short_code,
    currencyName,
    country
  ) => {
    // Update the state
    window.location.reload();

    setDefaultCurrency(selectedCurrency);

    Cookies.set("defaultCurrencyName", currencyName, { expires: 365 }); // Store currency name in cookies
    Cookies.set("defaultCurrencyId", currencyId, { expires: 365 });
    Cookies.set("defaultCountry", country, { expires: 365 }); // Store currency ID in cookies

    dispatch(changeCountryId(currencyId));
    dispatch(changeCountry(country));
    dispatch(changeCountryCode(short_code));
    dispatch(changeCurrency(currencyName));
  };

  const handleVisibleChange = (visible) => {
    if (visible) {
      // Dropdown is being opened

      document.body.classList.add("no-scroll", "overlay-container");
      // Call your function or perform actions when the dropdown opens
    } else {
      // Dropdown is being closed
      document.body.classList.remove("no-scroll", "overlay-container");
      // Call your function or perform actions when the dropdown closes
    }
    setIsDropdownOpen(visible);
  };

  const items = showCurrencyDrop
    ? [
      {
        key: "0",
        label: (
          <List
            size="small"
            dataSource={storefrontListData?.country_list}
            style={{ width: "220px", padding: "20px 5px 5px 5px" }}
            renderItem={(item, index) => (
              <List.Item
                style={{
                  padding: "4px 0px",
                  fontSize: "12px",
                }}
              >
                <Radio.Group
                  className="currency-radioGroup"
                  value={defaultCurrency}
                  onChange={(e) => {
                    const selectedCurrency = e.target.value;
                    const selectedItem = storefrontListData.country_list.find(
                      (item) => item.currency === selectedCurrency
                    );
                    if (selectedItem) {
                      handleCurrencyChange(
                        selectedCurrency,
                        selectedItem.id,
                        selectedItem.short_code,
                        selectedItem.currency,
                        selectedItem.country
                      );
                    }
                  }}
                >
                  {/* {storefrontListData.country_list.map((item) => ( */}
                  <Radio value={item.currency} key={item.id}>
                    <Row
                      className="radio-item"
                      align="middle"
                      justify={"space-between"}
                    >
                      <Col span={18} className="currency-name">
                        {item.currency}
                      </Col>
                      <Col span={6}>
                        {displayCountryFlag(item?.short_code)}
                      </Col>
                    </Row>
                  </Radio>
                  {/* ))} */}
                </Radio.Group>
              </List.Item>
            )}
          />
        ),
      },
      {
        key: "1",
        label: (
          <div
            style={{
              textAlign: "center",
              padding: "10px 0px 0px 0px",
              borderTop: " 1px solid #f2f3f7",
            }}
            onClick={() => {
              handleVisibleChange(false);
            }}
          >
            {/* TODO: dev place condition here */}
            <AiFillCloseCircle style={{ fontSize: "20px" }} />
          </div>
        ),
      },
    ]
    : [];

  //? States---------------------------------------------------------------------------->

  const [countryData, setCountryData] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(
    initialDefaultCurrency
  );
  const [countryValue, setCountryValue] = useState(defaultCountryFromCookies);

  const defaultCountry = useSelector(
    (state) => state.multiLocation.defaultCountry
  );
  const defaultCountryId = useSelector(
    (state) => state.multiLocation.defaultCountryId
  );
  const cartCountTotalValue = useSelector(
    (state) => state.cartSlice.cartCountTotal
  );

  //? Functions ------------------------------------------------------------------>
  // Define the API endpoint for geolocation
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const initializeData = async () => {
    if (!defaultCountryId && path !== "/") {
      const geoLocationUrl =
        "https://pro.ip-api.com/json/?fields=61439&key=r8KxA4oLfB365n8";
      const countryDataUrl = `${process.env.NEXT_PUBLIC_BACKEND}/storefront/store_list`;

      try {
        const apiData = await fetchData(geoLocationUrl);

        // setGeoLocationData(apiData);

        const countryListData = await fetchData(countryDataUrl);

        setCountryData(countryListData);

        const matchingCountry = countryListData?.country_list.find(
          (countryItem) => countryItem.country === apiData.country
        );

        if (matchingCountry) {
          Cookies.set("defaultCurrencyName", matchingCountry.currency, {
            expires: 365,
          });

          Cookies.set("defaultCurrencyId", matchingCountry.id, {
            expires: 365,
          });

          Cookies.set("defaultCountry", matchingCountry.country, {
            expires: 365,
          });

          Cookies.set("geoLocation", matchingCountry.country, {
            expires: 365,
          });

          // setMyDefaultCountry(matchingCountry);
          dispatch(setDefaultCountry(matchingCountry));
          dispatch(changeCountryId(matchingCountry.id));
          dispatch(changeCountry(matchingCountry.country));
          dispatch(changeCountryCode(matchingCountry.short_code));
          dispatch(changeCurrency(matchingCountry.currency));
        } else {
          console.log("No matching country found");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // useEffect(() => {
  //   if (displayMobileSearchMenu) {
  //     document.querySelector("html").classList.add("hide-overflow");
  //     document.querySelector("html").classList.add("hide-mobile-bottom-nav");
  //   } else {
  //     document.querySelector("html").classList.remove("hide-overflow");
  //     document.querySelector("html").classList.remove("hide-mobile-bottom-nav");
  //   }
  //   return () => {
  //     document.querySelector("html").classList.remove("hide-overflow");
  //     document.querySelector("html").classList.remove("hide-mobile-bottom-nav");
  //   };
  // }, [displayMobileSearchMenu, window.location, path]);

  useEffect(() => {
    console.log("headerData", data)
  }, [data])


  useEffect(() => {
    setTimeout(() => {
      if (path === "/") {
        setDisplayMobileSearchMenu(false);
        document
          .querySelector(".mobile-bottom-nav")
          .classList.add("display-flex");
      }
    }, 700);
  }, [path]);

  useEffect(() => {
    setDisplayMobileSearchMenu(false);
  }, [path]);

  useEffect(() => {
    if (showSideNav) {
      // if (document.querySelector(".mobile-bottom-nav")) {
      // document.querySelector(".mobile-bottom-nav").classList.remove("display-flex");
      document.querySelector("html").classList.add("hide-mobile-bottom-nav");
      // }
    } else {
      // if (document.querySelector(".mobile-bottom-nav")) {
      // document.querySelector(".mobile-bottom-nav").classList.add("display-flex");
      document.querySelector("html").classList.remove("hide-mobile-bottom-nav");
      // }
    }
  }, [showSideNav]);

  useEffect(() => {
    initializeData();
  }, [countryData, defaultCountry]);
  useEffect(() => {
    const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
    const defaultCountryFromCookies = Cookies.get("defaultCountry");

    setDefaultCurrency(defaultCurrencyFromCookies);
    setCountryValue(defaultCountryFromCookies);
  }, []);

  useEffect(() => {
    setDefaultCurrency(defaultCurrencyFromCookies);
    setCountryValue(defaultCountryFromCookies);
  }, [defaultCurrencyFromCookies, defaultCountryFromCookies]);

  return (
    <>
      {/* {path !== "/wishlist" && ( */}
      <>
        {data?.announcement_bar?.enable && (
          <div className="bg-primary padding5">
            <marquee behavior="scroll" direction="left">
              <Text className="text-white font-400 font12 ">
                {data?.announcement_bar?.announcement_text}{" "}
                <Link
                  href={data?.announcement_bar?.button_link || "#"}
                  className="font12 font-600 text-white text-underline marginLeft5"
                >
                  {data?.announcement_bar?.button_text}
                </Link>
              </Text>
            </marquee>
          </div>
        )}
        <Row className={`mobile-header theme-type-${themeType}`} align="middle"
          style={{
            backgroundColor: themeType === "premier" ? "#DEBD5C" : "#fff",
            width: themeType === "premier" ? "100% !important" : "99% !important",
          }}
        >
          <Col className="menu-hamburger">
            {/* <MobileNav data={props?.data} /> */}

            {path === "/account" ? (
              <SideNav />
            ) : (
              <>
                {/* <Image
                  alt="hamburger"
                  src={Hamburger}
                  height="auto"
                  width={43}
                  onClick={() => setShowSideNav(!showSideNav)}
                /> */}
                <div
                  height="auto"
                  width={43}
                  onClick={() => setShowSideNav(!showSideNav)}
                >
                  <Hamburger />
                </div>
                {/* <AiOutlineMenu onClick={() => setShowSideNav(!showSideNav)} /> */}

                <SideNavDrawer
                  data={data?.navigation_bar?.category_structure}
                  showSideNav={showSideNav}
                  setShowSideNav={setShowSideNav}
                  userAgent={userAgent}
                />
              </>
            )}
          </Col>
          <Col>
            <Link href="/">
              <Image
                width={130}
                height="auto"
                src={themeType === "premier" ? whiteLogo.src : logo.src}
                alt="Logo"
                preview={false}
              />
            </Link>
          </Col>
          <Col
            // style={{
            //   display: "flex",
            //   justifyContent: "end",
            //   marginRight: "10px",
            // }}
            // span={8}
            // offset={4}
            className="icons-section mobile-header-account-container"
          >
            <Space className="bg-gray-1 borderRadius50 padding10">
              {/* {path !== "/" && (
                <div>
                  <div
                    onMouseOver={() => setSelectedIcon(2)}
                    onMouseOut={() => setSelectedIcon(null)}
                    className="currencyDropDown "
                  >
                    <Dropdown
                      menu={{ items }}
                      overlayClassName="currency-dropDown"
                      placement="bottomRight"
                      width={"200px"}
                      autoFocus={false}
                      trigger={["click"]}
                      onVisibleChange={handleVisibleChange}
                      visible={isDropdownOpen}
                      onMouseEnter={() => {
                        setShowCurrencyDrop(true);
                      }}
                    >
                      <Text
                        className={
                          selectedIcon === 2
                            ? "icon-container icon-container-active"
                            : "icon-container"
                        }
                      >
                        <div className="currency-dropdown-mobile-new-menu">
                          {defaultCurrencyFromCookies ? (
                            <div className="currency-div-mobile-screen">
                              {defaultCurrencyFromCookies}{" "}
                            </div>
                          ) : (
                            <div className="loading-dots">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                          <div className="currency-dropdown-icon-mobile">
                            <BiChevronDown />
                          </div>
                        </div>
                      </Text>
                    </Dropdown>
                  </div>
                </div>
              )} */}
              {/* <BsSearch /> */}
              {/* <Link href="/search"> */}
              {/* <BsSearch
                onClick={() => {
                  scrollToTop();
                  setDisplayMobileSearchMenu(true);
                }}
              /> */}

              {/* <Link
                href={
                  loggedIn && comverse_customer_token ? "/wishlist" : "/login"
                }
              >
                <AiOutlineHeart />
              </Link> */}

              {/* <Link
                href={`/cart/checkout?id=${cartId}`}
                style={{ position: "relative" }}
              >
                {cartCountTotalValue > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      textAlign: "center",
                      top: "2px",
                      fontSize: "9px",
                      fontWeight: "bold",
                      color: "#ff0d50",
                      width: "100%",
                    }}
                  >
                    {cartCountTotalValue}
                  </div>
                )}
                <BsHandbag />
              </Link> */}

              <LoginDropdown isMobile={isMobile} />

              {/* <Link
                href="/login"
                onClick={() => {
                  dispatch(changeActiveMenuReducer("dashboard"));
                }}
              >
                <Image
                  alt="hamburger"
                  src={LoginUserIcon}
                  height="auto"
                  width={19}
                />
              </Link> */}
            </Space>
          </Col>
        </Row>
        {/*TODO: 9QH7567KJKJ%&767^ */}
        {displayMobileSearchMenu && (
          <Row>
            <Col flex="100%" style={{ overflowY: "scroll", height: "100vh" }}>
              <div>
                <SearchForMobile
                  userAgent={{ isMobile, isTablet, isDesktop, userAgent }}
                  setDisplayMobileSearchMenu={setDisplayMobileSearchMenu}
                />
              </div>
            </Col>
          </Row>
        )}
      </>
      {/* // )} */}
    </>
  );
}

export default MobileHeader;
