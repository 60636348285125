"use client";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { usePathname } from "next/navigation";

export default function Providers({
  children,
  customerToken,
  UserTheme,
  UserType,
}) {
  const [BGColor, setBGColor] = useState();
  const [textColor, setTextColor] = useState();
  const [loading, setLoading] = useState(true);
  const pathName = usePathname();
  useEffect(() => {
    if (pathName !== "/") {
      Cookies.set("guest-user", true, {
        expires: 365,
      });
    }
    if (UserType?.value === "islamic") {
      // ismalic theme
      Cookies.set("main_theme", "#007D84", {
        secure: true,
      });
      Cookies.set("secondary_theme", "#ffffff", {
        secure: true,
      });
      Cookies.set("user_type", "islamic", {
        expires: 365,
      });
      setBGColor("#007D84");
      setTextColor("#ffffff");
      setLoading(false);
    } else if (UserType?.value === "premier") {
      // premier theme
      Cookies.set("main_theme", "rgb(223, 189, 92)", {
        secure: true,
      });
      Cookies.set("secondary_theme", "#000000", {
        secure: true,
      });
      Cookies.set("user_type", "premier", {
        expires: 365,
      });
      setBGColor("rgb(223, 189, 92)");
      setTextColor("#ffffff");
      setLoading(false);
    } else {
      Cookies.set("main_theme", "#d62314", {
        secure: true,
      });
      Cookies.set("secondary_theme", "#ffffff", {
        secure: true,
      });
      Cookies.set("user_type", "default", {
        expires: 365,
      });
      setBGColor("#d62314");
      setTextColor("#ffffff");
      setLoading(false);
    }
  }, [customerToken?.value, UserType]);

  return (
    <>
      {!loading && (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 14,
              borderRadius: 6,
              colorText: "#292929",
              colorPrimary: "#D62314",
              colorTextBase: "#B7B7B7",
              fontFamily: "ZemestroStd-Regular",
              fontWeight: 100,
            },
            components: {
              Typography: {
                colorText: "#000000",
                fontFamilyCode: "ZemestroStd-Regular",
                fontSizeHeading1: 24,
                fontSizeHeading2: 22,
                fontSizeHeading3: 18,
                fontSizeHeading4: 16,
                fontSizeHeading5: 14,
              },
              Checkbox: {
                colorPrimary: "#D62314",
              },
              Radio: {
                borderRadius: 0,
                colorPrimaryHover: "#D62314",
              },
              Button: {
                colorText: "#FFFFFF",
                colorPrimary: BGColor,
                colorPrimaryHover: BGColor,
                borderRadiusLG: 30,
                colorBgContainerDisabled: "#b7b7b7",
                colorTextDisabled: "#ffffff",
              },
              Input: {
                borderRadius: 6,
                colorBorder: "#D9D9D9",
              },
              Select: {
                borderRadius: 0,
              },
            },
          }}
        >
          <div
            style={{ "--background-color": BGColor, "--text-color": textColor }}
          >
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {() => children}
              </PersistGate>
            </Provider>
          </div>
        </ConfigProvider>
      )}
    </>
  );
}
