"use client";

// Library Imports
// import axios from "axios";
import { useEffect, useState } from "react";
import { Row, Col, Typography, Space, Image } from "antd";

// Assets, Media & StyleSheets 🚀
import "./Footer.scss";

// Footer logos for customer policies and payment options

import {
  FaTiktok,
  FaYoutube,
  FaTwitter,
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
} from "react-icons/fa";

import visa from "../../../assets/images/footer/1f4baa60-visa-logo-old.png";
import applePay from "../../../assets/images/footer/Apple_Pay-Logo.wine.svg";
import paypal from "../../../assets/images/footer/2560px-PayPal_logo.svg.png";
import mastercard from "../../../assets/images/footer/MasterCard_Logo.svg.png";
import ginkgo_logo from "../../../assets/images/footer/ginkgo_logo.png";
import comverse_logo from "../../../assets/images/footer/comverse_logo.png";
import comverse_ginkgo_logo from "../../../assets/images/footer/comverse_ginkgo_logo.svg";
import googlePlay from "../../../assets/images/googlePlay.png";
import AppStore from "../../../assets/images/AppStore.png";
import QrcodeImage from "../../../assets/images/Qrcode 1.png";

import AddedFooter from "./AddedFooter";
import { UpperFooter } from "./UpperFooter";
import Link from "next/link";
import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import { usePathname } from "next/navigation";
// import Image from "next/image";

// Library Constants
const { Text, Title } = Typography;

export default function Footer({ data, userAgent }) {
  const pathName = usePathname();

  // const [colWidth, setColWidth] = useState(0);
  const colWidth = 24 / (data?.navigations?.length + 1);

  const { isDesktop, isTablet, isMobile } = userAgent;

  return (
    <div className="paddingTop40 footer-container homepage-cached">
      <div className={!isDesktop ? "footer footer-mobile" : "footer"}>
        {/* <UpperFooter footer={data} userAgent={userAgent} /> */}
        <Col
          span={24}
          className="flexColumn flexCenter nav-links-container-wrapper"
        >
          <Row
            style={{
              backgroundColor: data?.background_color,
            }}
          >
            <UpperFooter footer={data} userAgent={userAgent} />
            {data?.navigations?.map((item, index) => (
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={24}
                xs={24}
                key={index}
                className="nav-links-container"
              >
                <Title
                  level={4}
                  style={{
                    color: data?.text_color,
                    fontFamily: "ZemestroStd-Regular",
                    fontWeight: 100,
                  }}
                >
                  {item.title}
                </Title>
                <Row className="flex-links-wrap">
                  {item?.menu?.map((nav, index) => (
                    <Col className="nav-link" key={index}>
                      <Link
                        href={nav?.link ? nav?.link : "/"}
                        style={{ color: data?.text_color }}
                      >
                        {nav.label}
                      </Link>
                      &nbsp;
                      {(isMobile || isTablet) &&
                        index !== item.menu?.length - 1 && <span>|</span>}
                      &nbsp;
                    </Col>
                  ))}
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </div>
      {isDesktop ? (
        <Col
          span={24}
          just
          style={{
            gap: "5px",
            padding: "10px",
          }}
        >
          <Row
            style={{
              justifyContent: "center",
              gap: "8px",
              marginBottom: isMobile ? "20px" : "",
            }}
            className="tech-partner"
          >
            <Link
              href="https://ginkgoretail.com/ecommerce-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                style={{
                  color: "#0E0D0D",
                  alignSelf: "center",
                  opacity: "0.5",
                  fontSize: isMobile ? "10px" : "",
                }}
              >
                © Copyright AlfaMall 2025. All right reserved.
              </Text>
              <p style={{ fontSize: isMobile ? "10px" : "" }}>
                Technology Platform -{" "}
                <div style={{ alignSelf: "center", display: "flex" }}>
                  <Image
                    src={comverse_ginkgo_logo.src}
                    width={isDesktop ? 181 : 100}
                    height="auto"
                    objectFit="contain"
                    alt="ginkgo retail"
                    preview={false}
                  />
                </div>
              </p>
            </Link>
          </Row>
        </Col>
      ) : isMobile && (pathName.includes("/") || pathName.includes("pages")) ? (
        <Col
          span={24}
          just
          style={{
            gap: "5px",
            padding: "10px",
          }}
        >
          <Row
            style={{
              justifyContent: "center",
              gap: "8px",
              marginBottom: isMobile ? "20px" : "",
            }}
            className="tech-partner"
          >
            <Link
              href="https://ginkgoretail.com/ecommerce-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                style={{
                  color: "#0E0D0D",
                  alignSelf: "center",
                  opacity: "0.5",
                  fontSize: isMobile ? "10px" : "",
                }}
              >
                © Copyright AlfaMall 2025. All right reserved.
              </Text>
              <p style={{ fontSize: isMobile ? "10px" : "" }}>
                Technology Platform -{" "}
                <div style={{ alignSelf: "center", display: "flex" }}>
                  <Image
                    src={comverse_ginkgo_logo.src}
                    width={isDesktop ? 181 : 100}
                    height="auto"
                    objectFit="contain"
                    alt="ginkgo retail"
                    preview={false}
                  />
                </div>
              </p>
            </Link>
          </Row>
        </Col>
      ) : null}

      {/* : (
        <Col
          span={24}
          just
          style={{ backgroundColor: "black", padding: "10px" }}
        >
          <Row
            style={{ justifyContent: "center", gap: "5px" }}
            className="tech-partner"
          >
            <Link
              href="https://ginkgoretail.com/ecommerce-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: "10px",
                  alignSelf: "center",
                  marginTop: "1px",
                  fontWeight: "bold",
                }}
              >
                Technology Platform -  
              </Text>
              <div style={{ alignSelf: "center", display: "flex" }}>
                <Image
                  src={comverse_ginkgo_logo}
                  height={20}
                  style={{ objectFit: "contain" }}
                  alt="ginkgo retail"
                />
              </div>
            </Link>
          </Row>
        </Col>
      )} */}
      {/* <Col span={24}>
        <AddedFooter footer={data} userAgent={userAgent} />
      </Col> */}
    </div>
  );
}
