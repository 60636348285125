import React from "react";

const WhatsappIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 43.6496L3.06751 32.4573C1.18141 29.1826 0.176175 25.4622 0.186538 21.6589C0.186538 9.74118 9.88651 0.0515747 21.8042 0.0515747C27.5869 0.0515747 33.0172 2.30039 37.0899 6.3835C41.173 10.4666 43.4219 15.8969 43.4115 21.6692C43.4115 33.5869 33.7115 43.2765 21.7938 43.2765H21.7835C18.1667 43.2765 14.6121 42.3646 11.4514 40.6443L0 43.6496ZM11.9902 36.727L12.6431 37.1208C15.3997 38.7582 18.5605 39.6183 21.7835 39.6287H21.7938C31.6907 39.6287 39.7533 31.5765 39.7533 21.6692C39.7533 16.8711 37.8879 12.3631 34.4991 8.96394C31.1104 5.5648 26.592 3.69943 21.7938 3.69943C11.8866 3.69943 3.82403 11.7516 3.82403 21.6589C3.82403 25.0476 4.76708 28.3535 6.57028 31.2138L6.99517 31.8977L5.18161 38.5198L11.9902 36.727Z"
        fill="black"
        fillOpacity="0.24"
      ></path>
      <path
        d="M0.980957 42.4943L3.90804 31.8197C2.10676 28.6981 1.15494 25.1467 1.15494 21.5237C1.15494 10.1633 10.407 0.921509 21.7571 0.921509C27.2736 0.921509 32.442 3.07077 36.3312 6.95991C40.2203 10.849 42.3593 16.0277 42.3593 21.5339C42.3593 32.8943 33.1073 42.1361 21.7571 42.1361H21.7469C18.2979 42.1361 14.9102 41.2662 11.9012 39.6287L0.980957 42.4943Z"
        fill="url(#paint0_linear_58_66)"
      ></path>
      <path
        d="M0.233398 43.2415L3.26283 32.1881C1.40014 28.954 0.407386 25.2798 0.417621 21.5237C0.417621 9.75394 9.99718 0.184616 21.7669 0.184616C27.4778 0.184616 32.8407 2.40552 36.8629 6.43794C40.8953 10.4704 43.1162 15.8333 43.106 21.5339C43.106 33.3037 33.5265 42.873 21.7567 42.873H21.7465C18.1746 42.873 14.6641 41.9724 11.5426 40.2734L0.233398 43.2415ZM12.0748 36.4048L12.7196 36.7937C15.442 38.4107 18.5635 39.2602 21.7465 39.2704H21.7567C31.5307 39.2704 39.4932 31.3182 39.4932 21.5339C39.4932 16.7953 37.651 12.3433 34.3043 8.98635C30.9576 5.62941 26.4953 3.78718 21.7567 3.78718C11.9725 3.78718 4.00995 11.7394 4.00995 21.5237C4.00995 24.8704 4.9413 28.1352 6.72212 30.96L7.14173 31.6355L5.35068 38.1753L12.0748 36.4048Z"
        fill="white"
      ></path>
      <path
        d="M16.4247 12.5889C16.0255 11.6985 15.6059 11.678 15.2272 11.6678C14.9202 11.6576 14.562 11.6576 14.2038 11.6576C13.8455 11.6576 13.2724 11.7906 12.7811 12.3228C12.2899 12.855 10.9185 14.1446 10.9185 16.7749C10.9185 19.3949 12.8323 21.9331 13.0984 22.2913C13.3645 22.6495 16.7931 28.2069 22.2072 30.3459C26.7104 32.1267 27.6315 31.7685 28.6038 31.6764C29.5863 31.5843 31.756 30.3868 32.2064 29.1382C32.6464 27.8896 32.6464 26.8252 32.5134 26.6C32.3804 26.3749 32.0221 26.2418 31.4899 25.9757C30.9577 25.7096 28.3377 24.4201 27.8464 24.2359C27.3552 24.0516 26.997 23.9698 26.649 24.502C26.2908 25.0342 25.2673 26.2316 24.9603 26.5898C24.6532 26.948 24.336 26.989 23.8038 26.7229C23.2716 26.4568 21.5522 25.8939 19.5155 24.0721C17.9291 22.6597 16.8545 20.9096 16.5475 20.3774C16.2404 19.8452 16.5168 19.5587 16.7829 19.2926C17.0183 19.0572 17.3151 18.6682 17.5812 18.3612C17.8473 18.0542 17.9394 17.829 18.1134 17.4708C18.2873 17.1126 18.2055 16.8056 18.0724 16.5395C17.9394 16.2734 16.8954 13.6431 16.4247 12.5889Z"
        fill="white"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_58_66"
          x1="21.6695"
          y1="42.4914"
          x2="21.6695"
          y2="0.919185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038"></stop>
          <stop offset="1" stopColor="#60D66A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WhatsappIcon;
