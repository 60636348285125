"use client";
// Library Imports
import {
  Col,
  Form,
  Input,
  Button,
  Typography,
  notification,
  Image,
} from "antd";
import axios from "axios";
import { useState } from "react";
import "./UpperFooter.scss";
import sendIcon from "../../../assets/Icons/sendIcon.svg";

// Ant Design constants
const { Text } = Typography;

export const UpperFooter = ({ footer, userAgent }) => {
  const { isMobile } = userAgent;
  const [canSubmit, setCanSubmit] = useState(true);
  const [lastNotificationTime, setLastNotificationTime] = useState(0);
  const subscribeNewsLetter = (values) => {
    if (!canSubmit) {
      const now = Date.now();
      if (now - lastNotificationTime >= 10000) {
        notification["info"]({
          message: "Please wait 1 minute before trying again.",
        });
        setLastNotificationTime(now);
      }
      return;
    }

    if (window.Moengage && window.Moengage.add_email) {
      window.Moengage.add_email(values?.email);
      window.Moengage.add_unique_user_id(values?.email);
    }
    axios
      .post(
        process.env.REACT_APP_BACKEND_HOST + "/storefront/newsletter",
        values
      )
      .then((response) => {
        notification["success"]({
          message: "Email Successfully Subscribed!",
        });
        setCanSubmit(false);
        setTimeout(() => setCanSubmit(true), 60000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notification["error"]({
            message: "Email Already Registered!",
          });
        }
        console.log(err);
      });
  };

  return (
    <Col className="upper-footer">
      {footer?.show_news_letter && (
        <>
          <Col className="subscribe-titles">
            <Text className="exclusive font26Bold">Exclusive</Text>
            <Text className="subscribe font18Regular">Subscribe</Text>
            <Text className="discount font14">
              Get 10% off your first order
            </Text>
          </Col>
          <Form name="basic" size="large" onFinish={subscribeNewsLetter}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email!",
                },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Please enter a valid email!",
                },
              ]}
              style={{ marginBottom: "5px" }}
            >
              <Input
                style={{ width: "200px" }}
                placeholder="Enter your email"
                suffix={
                  !isMobile && (
                    <Button htmlType="submit" type="text">
                      <Image
                        preview={false}
                        src={sendIcon.src}
                        width="15px"
                        height=""
                      />
                    </Button>
                  )
                }
              />
            </Form.Item>
          </Form>
        </>
      )}
    </Col>
  );
};
