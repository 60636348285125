"use client";

import React, { useEffect } from "react";

const GoogleAds = ({ ID }) => {
  // console.log(ID)
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${ID}`;
    script.async = true;

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    window.gtag("config", ID, {
      " allow_enhanced_conversions": true,
    });
  }, []);

  return null;
};

export default GoogleAds;
