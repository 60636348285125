"use client";
//? Library Imports ------------------------------------------------------------>
import Image from "next/image";
import { Row, Col, Space, Typography } from "antd";

//? Assets --------------------------------------------------------------------->
import Visa from "../../../../assets/images/footer/PaymentGateways/Visa.png";
import PayPal from "../../../../assets/images/footer/PaymentGateways/PayPal.png";
import ApplePay from "../../../../assets/images/footer/PaymentGateways/ApplePay.svg";
import MasterCard from "../../../../assets/images/footer/PaymentGateways/MasterCard.png";

//? Modules -------------------------------------------------------------------->
//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function PaymentGatewayFooter({ userAgent }) {
  const { isMobile, isTablet, isDesktop } = userAgent;
  return (
    <Col span={24}>
      <Row justify="center" gutter={[0, 15]}>
        <Col span={24} className="flexCenter width100 marginBottom20">
          <Text
            className={isDesktop ? "font16" : "font12"}
            style={{
              // color: "#FF0D50",
              color: "var(--background-color)",
              textAlign: "center",
              width: "100%",
            }}
          >
            AlfaMall - Copyright © {new Date().getFullYear()}
          </Text>
        </Col>

        {/* <Col>
          <Space
            align="center"
            className="flexCenter width100"
            size={isDesktop ? "large" : "middle"}
          >

            <Image
              // preview={false}
              src={Visa}
              width={45}
              height={14}
              alt="Visa"
            />
            <Image
              // preview={false}
              src={MasterCard}
              width={31}
              height={18}
              alt="MasterCard"
            />
          </Space>
        </Col> */}
      </Row>
    </Col>
  );
}
