import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Row, Drawer, Space, Col, message } from "antd";

import { AiOutlineMenu } from "react-icons/ai";

import { logoutReducer } from "@/redux/Slices/accountSlice";
import AccountStatus, { customerLogout } from "@/functions/Cookies/Cookies";
import DynamicButton from "@/components/shared/Buttons/DynamicButton/DynamicButton";

const { Text } = Typography;

export default function SideNav() {
  const router = useRouter();
  const dispatch = useDispatch();

  const {
    loggedIn,
    comverse_customer_id,
    comverse_customer_email,
    comverse_customer_token,
  } = AccountStatus();

  //? Redux States --------------------------------------------------------------->
  const comverse_customer_name = useSelector(
    (state) => state.accountSlice?.comverse_customer_name
  );

  const [open, setOpen] = useState(false);
  const [ActiveMenu, setActiveMenu] = useState("dashboard");
  const [DashboardDetails, setDashboardDetails] = useState({});

  //? Functions ------------------------------------------------------------------>
  const logOut = useCallback(() => {
    message.success("Logged out successfully!");
    customerLogout();
    dispatch(logoutReducer());
    router.push("/");
  }, [dispatch, router]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fetchDashboardDetails = useCallback(async () => {
    if (comverse_customer_token) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const payload = {
          method: "GET",
          headers: headers,
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND + "/storefront/customer_dashboard",
          payload
        );

        const data = await res.json();

        if (!res.ok) {
          if (data?.detail === "Invalid auth token") {
            customerLogout();
            dispatch(logoutReducer());
            router.push("/login");
          }
          //logout here
          throw new Error("Api Failed!");
        }

        setDashboardDetails(data);
        return data;
      } catch (error) {
        console.error("Error fetching data:", error.message);
        return null;
      }
    }
  }, [comverse_customer_token]);
  useEffect(() => {
    if (comverse_customer_token) {
      fetchDashboardDetails();
    }
  }, [comverse_customer_token, fetchDashboardDetails]);
  return (
    <Col className="flexCenter">
      <Space className="flexCenter">
        <AiOutlineMenu onClick={showDrawer} style={{ fontSize: "18px" }} />
      </Space>
      <Drawer
        open={open}
        width={238}
        placement="left"
        onClose={onClose}
        className="accountsSidebar"
        title={
          <Col className="padding15">
            <Col style={{ textAlign: "end", color: "#5A7184" }}>
              <Text className="font11" onClick={() => setActiveMenu("edit")}>
                Edit
              </Text>
            </Col>

            <Space direction="vertical">
              <Text className="margin0 font18Bold">Account</Text>
              <Text className="margin0 font18">
                {DashboardDetails?.profile
                  ? DashboardDetails?.profile?.full_name
                  : "N/A"}
              </Text>
            </Space>
          </Col>
        }
      >
        <Col className="sideBar">
          <Col span={24} className="padding0">
            <Col>
              <Row className="width100">
                <Col
                  span={24}
                  onClick={() => setActiveMenu("dashboard")}
                  className={
                    ActiveMenu === "dashboard"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>Dashboard</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("orders")}
                  className={
                    ActiveMenu === "orders"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>My Order</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("wishlist")}
                  className={
                    ActiveMenu === "wishlist"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>Wishlist</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("address")}
                  className={
                    ActiveMenu === "address"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>Address Book</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("points")}
                  className={
                    ActiveMenu === "points"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>Reward Point</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("wallet")}
                  className={
                    ActiveMenu === "wallet"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>My Wallet</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("faq")}
                  className={
                    ActiveMenu === "faq"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>FAQs</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("about")}
                  className={
                    ActiveMenu === "about"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>About Us</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("terms")}
                  className={
                    ActiveMenu === "terms"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>Terms & Conditions</Text>
                </Col>
                <Col
                  span={24}
                  onClick={() => setActiveMenu("privacy")}
                  className={
                    ActiveMenu === "privacy"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft20"
                  }
                >
                  <Text>Privacy Policy</Text>
                </Col>
              </Row>
            </Col>
          </Col>

          <Col span={24}>
            <DynamicButton
              name="Logout"
              function={logOut}
              style={{
                border: "none",
                color: "#BC1B41",
                background: "none",
                justifyContent: "start",
              }}
            />
          </Col>
        </Col>
      </Drawer>
    </Col>
  );
}
