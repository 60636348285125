"use client";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import usePath from "@/hooks/usePath/usePath";
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";
import { setDefaultCountry } from "@/redux/Slices/multiLocationSlice";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import { SessionProvider } from "next-auth/react";

//? Modules -------------------------------------------------------------------->
import Footer from "@/components/shared/Footer/Footer";
import { RootStyleRegistry } from "@/RootStyleRegistry";
import Navigation from "@/components/shared/Header/Navigation/Navigation";
import MobileHeader from "@/components/shared/Header/MobileHeader/MobileHeader";
import MobileBottomNavigation from "@/components/shared/Header/MobileBottomNavigation/MobileBottomNavigation";
import AccountsHeaderMobile from "@/components/MobileApp/Headers/AccountsHeaderMobile/AccountsHeaderMobile";
import PaymentGatewayFooter from "@/components/MobileApp/Footers/PaymentGatewayFooter/PaymentGatewayFooter";
import SocialMediaFooter from "@/components/MobileApp/Footers/SocialMediaFooter/SocialMediaFooter";
import WhatsappChat from "../../components/WhatsappChat/index";
import Link from "next/link";
import { Typography } from "antd";
import CustomScrollbar from "@/components/CustomScrollBar/CustomScrollBar";

export default function LayoutsWrapper({
  children,
  userAgent,
  headerData,
  footerData,
  storefrontListData,
  myIpAddress,
  myIpLocation
}) {
  //? Hooks ---------------------------------------------------------------------->
  const path = usePath();
  const router = useRouter();
  const dispatch = useDispatch();
  const { isMobile, isTablet, isDesktop } = userAgent;
  const Text = Typography;
  useEffect(() => {
    if (!Cookies.get("geoLocation")) {
      Cookies.set("geoLocation", myIpLocation, {
        expires: 365,
      });
    }
    if (!Cookies.get("address")) {
      Cookies.set("address", myIpAddress, {
        expires: 365,
      });
    }
  }, [])

  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  return (
    <RootStyleRegistry>
      <SessionProvider>
        <CustomScrollbar
          mainColor={mainColor}
          secondaryColor={secondaryColor}
          targetClass="scroll"
        />
        <div
          className="header"
          style={{
            display:
              path === "/cart/checkout" || path === "/login" ? "none" : "",
          }}
        >
          {!isDesktop && (
            <>
              {path === "/account" && (
                <AccountsHeaderMobile
                  storefrontListData={storefrontListData}
                  data={headerData?.header}
                  userAgent={userAgent}
                />
              )}
              {path !== "/account" &&
                path !== "/cart/checkout" &&
                path !== "/search" &&
                path !== "/login" &&
                path !== "/password-protected" &&
                path !== "/login" && (
                  <MobileHeader
                    data={headerData?.header}
                    userAgent={userAgent}
                    storefrontListData={storefrontListData}
                  />
                )}
            </>
          )}

          {isDesktop &&
            path !== "/cart/checkout" &&
            path !== "/password-protected" &&
            path !== "/vertica-checkout" &&
            path !== "/vertica" &&
            path !== "/login" && (
              <>
                <Navigation
                  storefrontListData={storefrontListData}
                  data={headerData?.header}
                  userAgent={userAgent}
                />
              </>
            )}
        </div>

        <div
          onMouseOver={() => dispatch(setShowMegaMenu(false))}
          className="mega-menu"
          style={{
            marginBottom: isMobile && !path.includes("/login") ? "100px" : "",
          }}
        >
          {children}

          {!isDesktop && path === "/search"
            ? null
            : isMobile &&
            !["/checkout", "/cart", "/thankyou", "/login"].includes(path) && (
              <MobileBottomNavigation
                storefrontListData={storefrontListData}
                data={headerData?.header}
                userAgent={userAgent}
              />
            )}

          {path !== "/search" &&
            path !== "/cart/checkout" &&
            path !== "/password-protected" &&
            path !== "/login" ? (
            <Footer data={footerData?.footer} userAgent={userAgent} />
          ) : null}

          {/* {path !== "/search" || path === "/account" ? null : path !==
            "/login" ? (
            <>
              <PaymentGatewayFooter userAgent={userAgent} />
            </>
          ) : path === "/search" ||
            path === "/cart/checkout" ||
            path === "/password-protected" ? null : ( */}
          {/* <Footer data={footerData?.footer} userAgent={userAgent} /> */}
          {/* )} */}
        </div>
      </SessionProvider>
      {/* <WhatsappChat /> */}
    </RootStyleRegistry>
  );
}
