"use client";
import React, { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import Cookies from "js-cookie";

const ComverseInsight = ({ myIpAddress, myIpLocation }) => {
  const pathname = usePathname();

  useEffect(() => {

    let uuid = localStorage.getItem("uuid");
    let currentInteraction = localStorage.getItem("currentInteraction");
    let last_interaction_timestamp = localStorage.getItem(
      "last_interaction_timestamp"
    );

    const formatDateTime = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const currentDateTime = formatDateTime(new Date());

    if (!currentInteraction) {
      localStorage.setItem("last_interaction_timestamp", null);
      localStorage.setItem("currentInteraction", currentDateTime);
      last_interaction_timestamp = null;
    } else {
      localStorage.setItem("last_interaction_timestamp", currentInteraction);
      localStorage.setItem("currentInteraction", currentDateTime);
      last_interaction_timestamp = currentInteraction;
    }

    if (!uuid || uuid === "undefined" || uuid === null) {
      const crypto = window.crypto || window.msCrypto;
      const Guid = (function () {
        const EMPTY = "00000000-0000-0000-0000-000000000000";
        const _padLeft = (paddingString, width, replacementChar) =>
          paddingString.length >= width
            ? paddingString
            : _padLeft(
              replacementChar + paddingString,
              width,
              replacementChar || " "
            );
        const _s4 = (number) => {
          const hexadecimalResult = number.toString(16);
          return _padLeft(hexadecimalResult, 4, "0");
        };
        const _cryptoGuid = () => {
          const buffer = new window.Uint16Array(8);
          crypto.getRandomValues(buffer);
          return [
            _s4(buffer[0]) + _s4(buffer[1]),
            _s4(buffer[2]),
            _s4(buffer[3]),
            _s4(buffer[4]),
            _s4(buffer[5]) + _s4(buffer[6]) + _s4(buffer[7]),
          ].join("-");
        };
        const _guid = () => {
          let currentDateMilliseconds = new Date().getTime();
          return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            const r = (currentDateMilliseconds + Math.random() * 16) % 16 | 0;
            currentDateMilliseconds = Math.floor(currentDateMilliseconds / 16);
            return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
          });
        };
        const create = () =>
          crypto && crypto.getRandomValues ? _cryptoGuid() : _guid();
        return { newGuid: create, empty: EMPTY };
      })();
      const guid = Guid.newGuid();
      localStorage.setItem("uuid", guid);
      uuid = guid;
      initPixel(uuid, last_interaction_timestamp);
    } else {
      initPixel(uuid, last_interaction_timestamp);
    }
  }, [pathname, myIpLocation, myIpAddress]);

  const initPixel = async (uuid, last_interaction_timestamp) => {
    const url = `${process.env.NEXT_PUBLIC_BACKEND}/user_tracking/interactions`;
    let interactionType;
    if (window.location.href.includes("checkout/thankyou")) {
      interactionType = "order_placed";
    } else if (window.location.href.includes("checkout")) {
      interactionType = "checkout_view";
    } else {
      interactionType = "page_view";
    }

    const data = {
      user_uuid: uuid,
      interaction_type: interactionType,
      path: window.location.pathname,
      last_interaction_timestamp: last_interaction_timestamp,
      is_purchased: window.location.href.includes("checkout/thankyou") ? 1 : 0,
      country: myIpLocation || Cookies.get("geoLocation"),
      ip_address: myIpAddress || Cookies.get("address"),
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return null;
};

export default ComverseInsight;
