//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import NextImage from "next/image";
import { useState, useEffect } from "react";
import { Row, Col, Space, Typography, Image } from "antd";
//? Assets --------------------------------------------------------------------->
import "./AccountsHeaderMobile.scss";
import { BiUser } from "react-icons/bi";
import { BsHandbag } from "react-icons/bs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import logo from "../../../../assets/images/logos/logo.svg";
import { AiOutlineMenu, AiOutlineHeart, AiOutlineSearch } from "react-icons/ai";
import CartIcon from "../../../../assets/Icons/CartFilledIcon.svg";

//? Modules -------------------------------------------------------------------->
import usePath from "@/hooks/usePath/usePath";
// import SideNav from "../../AccountsPageMobile/Modules/SideNav/SideNav";
import MobileSearch from "@/components/shared/Search/MobileSearch/MobileSearch";
import SideNavDrawer from "@/components/shared/Header/Navigation/SideNavDrawer/SideNavDrawer";
import AccountsSideNavMobile from "../../Navigations/AccountsSideNavMobile/AccountsSideNavMobile";
import SearchForMobile from "@/components/shared/Search/MobileSearch/MobileSearchh";

//? Redux Reducers ------------------------------------------------------------->
import { useDispatch, useSelector } from "react-redux";
import { changeActiveMenuReducer } from "@/redux/Slices/accountSlice";
import Cookies from "js-cookie";
//? Library Constants ---------------------------------------------------------->
// const { Text } = Typography;

export default function AccountsHeaderMobile(props) {
  const cartCountTotalValue = useSelector(
    (state) => state.cartSlice.cartCountTotal
  );
  const { isMobile, isTablet, isDesktop } = props.userAgent;
  const userAgent = props.userAgent;
  const [displayMobileSearchMenu, setDisplayMobileSearchMenu] = useState(false);
  const dispatch = useDispatch();
  const path = usePath();
  const [showSideNav, setShowSideNav] = useState(false);
  const loggedInStateOfUser = useState(
    useSelector((state) => state?.accountSlice?.comverse_customer_name)
  );
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  // useEffect(() => {
  //   if (displayMobileSearchMenu) {
  //     document.querySelector("html").classList.add("hide-overflow");
  //     if (path === "/") {
  //       if (document.querySelector(".mobile-bottom-nav"))
  //         document
  //           .querySelector(".mobile-bottom-nav")
  //           .classList.add("hide-mobile-bottom-nav");
  //     } else {
  //       if (document.querySelector(".mobile-filters-bottom-nav"))
  //         document
  //           .querySelector(".mobile-filters-bottom-nav")
  //           .classList.add("hide-mobile-bottom-nav");
  //       if (document.querySelector(".product-buying-bottom-nav"))
  //         document
  //           .querySelector(".product-buying-bottom-nav")
  //           .classList.add("hide-mobile-bottom-nav");
  //     }
  //   } else {
  //     document.querySelector("html").classList.remove("hide-overflow");
  //     if (path === "/account") {
  //       if (document.querySelector(".mobile-bottom-nav"))
  //         document
  //           .querySelector(".mobile-bottom-nav")
  //           .classList.remove("hide-mobile-bottom-nav");
  //     } else {
  //       if (document.querySelector(".mobile-filters-bottom-nav"))
  //         document
  //           .querySelector(".mobile-filters-bottom-nav")
  //           .classList.remove("hide-mobile-bottom-nav");
  //       if (document.querySelector(".product-buying-bottom-nav"))
  //         document
  //           .querySelector(".product-buying-bottom-nav")
  //           .classList.remove("hide-mobile-bottom-nav");
  //     }
  //   }
  //   return () => {
  //     document.querySelector("html").classList.remove("hide-overflow");
  //     if (path === "/account") {
  //       if (document.querySelector(".mobile-bottom-nav"))
  //         document
  //           .querySelector(".mobile-bottom-nav")
  //           .classList.remove("hide-mobile-bottom-nav");
  //     } else {
  //       if (document.querySelector(".mobile-filters-bottom-nav"))
  //         document
  //           .querySelector(".mobile-filters-bottom-nav")
  //           .classList.remove("hide-mobile-bottom-nav");
  //       if (document.querySelector(".product-buying-bottom-nav"))
  //         document
  //           .querySelector(".product-buying-bottom-nav")
  //           .classList.remove("hide-mobile-bottom-nav");
  //     }
  //   };
  // }, [displayMobileSearchMenu]);

  return (
    <>
      {!displayMobileSearchMenu && (
        <div>
          <Row
            align="middle"
            justify="space-between"
            className="AccountsHeaderMobile"
          >
            <Col flex="0 0 auto" className="padding10">
              {path === "/account" ? (
                <div className="flexBetween">
                  <Space>
                    <Link href="/">
                      <ArrowLeftOutlined />
                    </Link>
                    {/* onClick={() => {
                         dispatch(changeActiveMenuReducer("dashboard"));
                       }} */}

                    {/* <SideNav /> */}
                    <AccountsSideNavMobile />
                    <Link href="/">
                      <Image
                        preview={false}
                        width={110}
                        height="auto"
                        src={logo.src}
                        alt="Logo"
                      />
                    </Link>
                  </Space>
                </div>
              ) : (
                <>
                  <AiOutlineMenu onClick={() => setShowSideNav(!showSideNav)} />
                  <SideNavDrawer
                    showSideNav={showSideNav}
                    userAgent={props?.userAgent}
                    setShowSideNav={setShowSideNav}
                    data={props?.data?.navigation_bar?.side_navigation}
                  />
                </>
              )}
            </Col>

            {/* <Col span={6}>
        <Link href="/">
          <Image width={120} src={logo} alt="Logo" />
        </Link>
      </Col> */}

            <Col flex="0 0 auto" className="icons-section padding10">
              <Space>
                {/* <MobileSearch userAgent={props?.userAgent} /> */}
                <div
                  onClick={() => {
                    setDisplayMobileSearchMenu(true);
                  }}
                >
                  <AiOutlineSearch />
                </div>
                <Link
                  href="/wishlist"
                  // onClick={() => {
                  //   dispatch(changeActiveMenuReducer("wishlist"));
                  // }}
                >
                  <AiOutlineHeart />
                </Link>
                <Link href="/cart/checkout" style={{ position: "relative" }}>
                  {cartCountTotalValue > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "-4px",
                        textAlign: "center",
                        top: "-13px",
                        fontSize: "9px",
                        fontWeight: "bold",
                        color: "white",
                        width: "10px",
                        height: "10px",
                        backgroundColor: mainColor,
                        borderRadius: "20px",
                        padding: "3px",
                        zIndex: "10",
                      }}
                    >
                      {cartCountTotalValue}
                    </div>
                  )}
                  <NextImage
                    height={20}
                    src={CartIcon}
                    alt="icon cartIcon"
                    style={{ opacity: 0.3 }}
                  />
                </Link>
                <Link
                  href={loggedInStateOfUser ? "/account" : "/login"}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("dashboard"));
                  }}
                >
                  <BiUser />
                </Link>
              </Space>
            </Col>
          </Row>
        </div>
      )}
      {displayMobileSearchMenu && (
        <Row>
          <Col flex="100%" style={{ overflowY: "scroll", height: "100vh" }}>
            <div>
              <SearchForMobile
                userAgent={{ isMobile, isTablet, isDesktop, userAgent }}
                setDisplayMobileSearchMenu={setDisplayMobileSearchMenu}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}
