//? Library Imports ------------------------------------------------------------>
import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer,
} from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createWebStorage from "redux-persist/es/storage/createWebStorage";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

//? Redux Configurations ------------------------------------------------------->
const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const persistConfig = {
  key: "redux",
  storage,
};

//? Redux Reducers ------------------------------------------------------------->
import cartSlice from "./Slices/cartSlice";
import counterSlice from "./Slices/counterSlice";
import accountSlice from "./Slices/accountSlice";
import checkoutSlice from "./Slices/checkoutSlice";
import wishListSlice from "./Slices/wishListSlice";
import multiLocationSlice from "./Slices/multiLocationSlice";
import megaMenuSlice from "./Slices/megaMenuSlice";

const rootReducers = combineReducers({
  cartSlice: cartSlice,
  counter: counterSlice,
  accountSlice: accountSlice,
  checkoutSlice: checkoutSlice,
  wishListSlice: wishListSlice,
  multiLocation: multiLocationSlice,
  megaMenu: megaMenuSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

// Create Redux store with state injected by the server
// let preloadedState;
// if (typeof window !== "undefined") {
//   preloadedState = window.__PRELOADED_STATE__;
//   // Allow the passed state to be garbage-collected
//   //   delete window.__PRELOADED_STATE__;
// }

export const store = configureStore({
  reducer: persistedReducer,
  // preloadedState,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
