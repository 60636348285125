// <!-- <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M5.5094 8.73438H37.4235V12.9672H5.5094V8.73438ZM5.5094 19.35H37.4235V23.65H5.5094V19.35ZM5.5094 30.0328H37.4235V34.2656H5.5094V30.0328Z" fill="#D62314"/>
// </svg> -->
import React from 'react';

const MyIcon = () => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5094 8.73438H37.4235V12.9672H5.5094V8.73438ZM5.5094 19.35H37.4235V23.65H5.5094V19.35ZM5.5094 30.0328H37.4235V34.2656H5.5094V30.0328Z"
      fill="#D62314"
    />
  </svg>
);

export default MyIcon;
