"use client";
import React, { useEffect } from "react";
// import ServiceWorkerScript from '../../app/ServiceWorkerScript.js';

const MyComponent = () => {
  useEffect(() => {
    const loadMoEngageServiceWorker = async () => {
      try {
        // Register MoEngage service worker
        if ("serviceWorker" in navigator) {
          // navigator.serviceWorker.register('https://cdn.moengage.com/webpush/releases/serviceworker_cdn.min.latest.js')
          navigator.serviceWorker
            .register(window.location.origin + "/" + "serviceworker.js")
            .then((registration) => {
              console.log("MoEngage service worker registration working")
            })
            .catch((error) => {
              console.error(
                "MoEngage service worker registration failed:",
                error
              );
            });
        }
      } catch (error) {
        console.error("Failed to load MoEngage service worker:", error);
      }
    };

    // Call the function to load MoEngage service worker
    loadMoEngageServiceWorker();

    // Clean-up function
    return () => {
      // Perform any clean-up if needed
    };
  }, []);

  return null
};

export default MyComponent;